import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/common/alert.service';
import { LoginService } from 'src/app/services/login.service';
import { ResourceService } from 'src/app/services/common/resource.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  hide: boolean = true;
  successMessage = ''; 
  hidePassword = true;
  hidePasswordConfirm = true;
  submitted = false;
  resource: any = {};

  queryString = Object();
  resetForm: FormGroup;

  get f() { return this.resetForm.controls; }

  constructor(private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private snackBar: MatSnackBar
  ) { }

    async ngOnInit(): Promise<void> {
    this.loadResoruce();
    this.resourceService.languageSubject.subscribe(response=> {
        this.resource = response;
    });

    this.queryString = this.activatedRoute.snapshot.queryParams;
    this.buildForm();

    if (this.queryString?.token) {
      var token = this.queryString?.token;
      var userId = window.atob(token);
      this.f.userId.setValue(userId);
    }
    else {
      this.alertService.showError("Invalid link");
      this.router.navigate(['login']);
    }
  }

  loadResoruce() {
    this.resourceService.loadResoruce().subscribe(
        response => {
            this.resource = response;
        });
}
  buildForm(): void {
    this.resetForm = this.formBuilder.group({
      userId: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{6,20}$'),
        ]],
      confirmPassword: ['', [Validators.required]],
    }, {
      validators: this.passwordMatch.bind(this)
    }
    );
  }

  passwordMatch(formGroup: FormGroup) {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    const matchingControl = formGroup.controls['confirmPassword'];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (password?.value === confirmPassword?.value) {
      matchingControl.setErrors(null);
    }
    else {
      matchingControl.setErrors({ mustMatch: true });
    }
  }

  togglePasswordVisibility(event: Event) {
    event.stopPropagation(); 
    event.preventDefault(); 
    this.hide = !this.hide;
  }
  toggleConfirmPasswordVisibility(event: Event) {
    event.stopPropagation();
    event.preventDefault(); 
    this.hidePasswordConfirm = !this.hidePasswordConfirm;
  }

  public onSubmit() {
  
    this.submitted = true;
    if (this.resetForm.invalid)
      return;

    var data: any = {
      "userId": this.f.userId.value,
      "password": this.f.password.value
    }

   
  

    this.loginService.resetPassword(data).subscribe((res: any) => {
      if (res.success) {
        this.successMessage = "Password reset successfully";
    
   this.snackBar?.open(this.successMessage, 'Close', {
     duration: 3000, 
     panelClass: ['success-snackbar']
   });
        this.router.navigate(['/login']);
      }
    })
  }

  onClickBack() {
    this.router.navigate(['/login']);
  }
}