<div class="page-title-area" style="background-image: url(assets/img/page-title/page-title5.jpg);">
  <div class="container">
    <h1>{{resource.quick_tips}}</h1>
  </div>
</div>

<div class="how-it-works-area pt-5 pb-3">
  <div class="container">
    <h1 class="heading pb-3 text-center">{{resource.quick_tips_heading}}</h1>
    <div class="single-how-it-works-box">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 how-it-works-content">
          <h3>{{resource.quick_tips_heading}}</h3>
          <ol>
            <li>{{resource.quick_tips_iphone_users_point1}} <a href="https://onoffer.live"
                target="_blank">www.onoffer.live</a></li>
            <li>{{resource.quick_tips_iphone_users_point2}}</li>
            <li>{{resource.quick_tips_iphone_users_point3}}</li>
            <li>{{resource.quick_tips_iphone_users_point4}}</li>
          </ol>
          <!-- <a routerLink="/{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}</a> -->
        </div>
        <div class="col-lg-6 col-md-12 how-it-works-image">
          <img class="iphone" src="assets/img/quick-tips/iphone.png" alt="image">
        </div>
      </div>
    </div>
    <div class="single-how-it-works-box">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 how-it-works-content">
          <h3>{{resource.quick_tips_andriod_users}}</h3>
          <ol>
            <li>{{resource.quick_tips_andriod_users_point1}} <a href="https://onoffer.live" target="_blank">www.onoffer.live</a></li>
            <li>{{resource.quick_tips_andriod_users_point2}}</li>
            <li>{{resource.quick_tips_andriod_users_point3}}</li>
          </ol>
          <!-- <a routerLink="/{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}</a> -->
        </div>
        <div class="col-lg-6 col-md-12 how-it-works-image">
          <img class="android" src="assets/img/quick-tips/android.png" alt="image">
        </div>
      </div>
    </div>
    <p>{{resource.quick_tips_heading2_1}} <img class="logo" src="assets/img/logo.png" alt="image"> {{resource.quick_tips_heading2_2}}</p>
  </div>
</div>