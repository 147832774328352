import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/services/common/alert.service';
import { LoginService } from 'src/app/services/login.service';
import { UtilityService } from 'src/app/services/utility.service';
import { TermConditionPageComponent } from '../term-condition-page/term-condition-page.component';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DailogComponent } from '../../shared/common/dailog/dailog.component';
import { ResourceService } from 'src/app/services/common/resource.service';
import { IndexedDBService } from 'src/app/services/common/indexedDb.service';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
hide: boolean = true;
   
hidePassword = true;
hidePasswordConfirm = true;
submitted = false;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private alertService: AlertService,
        private formBuilderRegister: FormBuilder,
        private utilityService: UtilityService,
        private loginService: LoginService,
        private readonly sso: ScrollStrategyOptions,
        private resourceService: ResourceService,
        private indexedDbService: IndexedDBService,
    ) {
        this.scrollStrategy = this.sso.noop();
    }
    countryCode: any;
    countryList: any[];
    stateList: any[];
    cityList: any[];
    resource: any = {};

    submittedRegister = true;
                                       
    registerForm: FormGroup;
    get fReg() { return this.registerForm.controls; }

    scrollStrategy: ScrollStrategy;

    async ngOnInit(): Promise<void> {
        this.loadResoruce();
        this.resourceService.languageSubject.subscribe(response=> {
            this.resource = response;
        });
        // var authToken = this.userInfoService.getAccessToken();
        // if (authToken) {
        //     this.router.navigate(['/index']);
        // }
        //await this.getCountryList();
        this.buildForm();
    }

    loadResoruce() {
        this.resourceService.loadResoruce().subscribe(
            response => {
                this.resource = response;
            });
    }

    buildForm(): void {
        this.registerForm = this.formBuilderRegister.group({
            firstName: ['', [Validators.required]],
            //lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
            password: [
                '',
                [
                  Validators.required,
                  Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{6,20}$'),
                ]],
          confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z0-9@$!%*?&]{1,10}$')]],
           // country: ['', [Validators.required]],
            //stateId: ['', [Validators.required]],
            //cityId: ['', [Validators.required]],
            //countryCode: ['', [Validators.required]],
            //telephone: ['', [Validators.required, Validators.minLength(10)]],
            termsCondition: [true],
        }, {
            validators: this.passwordMatch.bind(this)
        });
    }

    passwordMatch(formGroup: FormGroup) {
        const password = formGroup.get('password');
        const confirmPassword = formGroup.get('confirmPassword');

        const matchingControl = formGroup.controls['confirmPassword'];

        // return if another validator has already found an error on the matchingControl
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }

        // set error on matchingControl if validation fails
        if (password?.value === confirmPassword?.value) {
            matchingControl.setErrors(null);
        }
        else {
            matchingControl.setErrors({ mustMatch: true });
        }
    }

    togglePasswordVisibility() {
        this.hide = !this.hide;
      }
      toggleConfirmPasswordVisibility(): void {
        this.hidePasswordConfirm = !this.hidePasswordConfirm;
      }

    // public async getCountryList() {
    //     this.countryList = await this.indexedDbService.getCountries();
    // }

    // public getStateList(countryId: any) {
    //     this.utilityService.getStatesList(countryId).subscribe((res: any) => {
    //         if (res.success) {
    //             this.stateList = res.data;
    //         }
    //     })
    // }

    // public getCityList(stateId: any) {
    //     this.utilityService.getCityList(stateId).subscribe((res: any) => {
    //         if (res.success) {
    //             this.cityList = res.data;
    //         }
    //     })
    // }

    // onChangeCountry(event: any) {
    //     if (event.value) {
    //         let country = this.countryList.find(x => x.id == event.value)
    //         if (country) {
    //             this.countryCode = country.code;
    //             this.getStateList(event.value);
    //             //this.fReg.countryCode.setValue(country.code);
    //         }
    //         else {
    //             this.countryCode = "";
    //             this.stateList = [];
    //             this.cityList = [];
    //             //this.fReg.countryCode.setValue('');
    //         }
    //     }
    //     else {
    //         this.countryCode = "";
    //         this.stateList = [];
    //         this.cityList = [];
    //         //this.fReg.countryCode.setValue('');
    //     }
    // }

    // onChangeState(event: any) {
    //     this.getCityList(event.value);
    //     this.fReg.cityId.setValue('');
    //     this.cityList = [];
    // }

    onSubmitRegister() {
        this.submittedRegister = true;

        if (this.registerForm.invalid)
            return;

        var model = Object.assign({});
        model.userType = ['CUSTOMER'];
        model.firstName = this.fReg.firstName.value;
        //model.lastName = this.fReg.lastName.value;
        model.email = this.fReg.email.value;
        model.password = this.fReg.password.value;
        // model.telephone = this.fReg.telephone.value;
        // model.countryId = this.fReg.country.value;
        // model.stateId = this.fReg.stateId.value;
        // model.cityId = this.fReg.cityId.value;
        model.termsCondition = this.fReg.termsCondition.value;

        this.loginService.register(model).pipe(first()).subscribe({
            next: (response) => {
                if (response.success) {
                    this.submittedRegister = false;
                    this.alertService.showSuccess(response.message);
                    this.router.navigate(['login']);
                } else {
                    this.alertService.showError(response.message);
                }
            },
            error: (error) => {
                this.alertService.showError(error.error.errorMessage);
            },
        });
    }

    onClickTermCondition() {
        let dialogRef = this.dialog.open(DailogComponent, {
            disableClose: true,
            scrollStrategy: this.scrollStrategy,
            width: '70%',
            data:
            {
                title: this.resource.term_conditions,
                component: TermConditionPageComponent
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.fReg.termsCondition.setValue(true);
        });
    }
}